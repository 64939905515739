.tasks__icon {
    background: #1d1f22;
    border-radius: 20px;
    height: 52px;
    width: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
        width: 24px;
        height: 24px;
    }
    &:after {
        content: "";
        background: #0066ff;
        width: 3.5px;
        height: 3.5px;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.tasks__block {
    background: #ffffff;
    box-shadow: 0px 0px 55px rgba(35, 65, 99, 0.1);
    border-radius: 50px;
    padding: 60px;
    position: relative;
    h3 {
        font-weight: 700;
        font-size: 40px;
        line-height: 49px;
        text-align: center;
        color: #000000;
        margin-bottom: 82px;
    }
    .tasks__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .tasks__item {
            width: 23%;
            .tasks__heading {
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                color: #000000;
                margin-top: 12px;
                margin-bottom: 30px;
            }
            .tasks__text {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
            }
        }
    }
    // &:after {
    //     content: '';
    //     position: relative;
    //     width: 250px;
    //     height: auto;
    //     background: url('../../../assets/images/ball.svg') no-repeat;
    // }
    @media (max-width: 991px) {
        padding: 24px 16px;
        box-shadow: 0px 0px 55px rgba(35, 65, 99, 0.1);
        border-radius: 24px;
        h3 {
            font-size: 24px;
            line-height: 29px;
            text-align: left;
            margin-bottom: 38px;
            margin-top: 0;
        }
        .tasks__content {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            .tasks__item {
                width: 100%;
                margin-bottom: 35px;
                .tasks__heading {
                    margin-bottom: 20px;
                }
                .tasks__text {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.tasks {
    background: url("../../../assets/images/ball.svg") no-repeat;
    padding-bottom: 170px;
    background-position-y: 150%;
    background-position-x: 50%;
    @media (max-width: 768px) {
        padding-bottom: 120px
    }
}