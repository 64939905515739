section h3 {
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    color: #000000;
    margin-bottom: 82px;
    @media (max-width: 991px) {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #000000;
        text-align: left;
        margin-bottom: 20px;
    }
}

.arch {
    margin-top: 20px;
    padding-bottom: 190px;
    @media (max-width: 991px) {
        padding-bottom: 30px;
        margin-top: 0;
    }
}

.arch__block {
    @media (max-width: 991px) {
        background: #ffffff;
        box-shadow: 0px 0px 55px rgba(35, 65, 99, 0.1);
        border-radius: 24px;
        padding-top: 28px;
    }
    .arch__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .arch__col {
            &_start {
                .arch__col-item {
                    &:after {
                        content: "";
                        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAAAjCAYAAAA33kzeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ0SURBVHgB1VldUtswEJYCDPAGw88Mb+4Nmhu0N0hPUOcEpTdITtByAnKD9gbhBqQnaPrEE0OAF/7dXVdK1+tdWSbJDPpmPPqxtCt9/rSSbWsSxNXV1Q4k7+Ga7O7uzswK0TGJAcjJOp3Ob7jGcJ3f3NzkZoVIjiAgJYcEFWSstdnLy8vp9fX1KRJnVoAUCSowBXKMS7GcQzoGknKzZCRHECimZKYoivICWHcrA5KWrqbkCEJ49Xg4onx+qWpKkiAEJQnzjLS5mi4uLjKzAJIkiCoGi0JdWYblmG9vby+kJmsSAkx65/b29hyyGVtWWntaHME1hHPT1LSARadPT09fIM24cZStlFLAU6oZhZ2mUi+14fB2JR/eBtT34P6OdD+ybvr8/Dzc398fmUjYh4eHMaQfNKNNjnGbBdg2fXjex48G375gST1mLLUj+eH3ID8ykWpCgmqzoYadwfm2ytUhITTRyOUQrG/7EJU2U5hHo5qCBGnlNm20JaP111QgoWkMTQ/K+RoBB8Ojo6OpEaDuYiHZS4Om2yyZbBEz6BhCnP2iqR29T07b3A6tyzc3N3Gn+yz65QpSWK482dgg+a/6f3yS4k1IZYLdmj1jjDTGMjbFPBwWm37e399/pWrqSB08JFVglrcJPU3NntRfuF+EDoS+LDw4y+eikcPqehsbG+eXl5fHc0OSgiSnTXVa3yYCNVVqfSUVSv2b7EttmI/R3t5evyRIcyoZUAY+3259G64K3PlCE9PIEAZe8UFUUFl+2ti1ebh8xcbj4+O7jr/JB8vW8xzKkuFv2OrEpHrpnuRLGiex1eqtgPt0RxdL6qcYi8RdDAdAnrhVYkfByQjFC9qW7iSx0m+7RKivmBjJ1IRnpE+Y7/DGfBsMqEI9PdM+gQAsqoLbcx/EanZpPz5mTgr3re3Irv4EDsPdw8PDCdatm/rEjQT/SkGXhZOQ5U7dpGr13oU36WyJsYMQYI2LL/4spLUPKUmKP6wvqqZ/cHBwRm1UYpAwOD5QToQ2sdpZhTYpqmcZS30qirJ8HDy4S8udxlJhS6cq+g4f/7ucnNIO3ealHYpJsOAD5Gh6ik1LqsketxXzXhhoh2/3fYkYD1TQ/L+SFieIMxsRLAvNhqRMoaw7ID6IMrQjfOUSNo0TKHdD5CCQoKEwADGI0UnyPG1iIiCplS3PgtVX2pB+qj++zBD4Tw3KH+EQeBzz05HGguzu7q7Mb21tGZ9/C8Cx4JggHPSg+C0UBqQ6v8QgPYHioM3f2FaHq7cACKY/YPI96Z50tHBKnULaB2LOTEsk99EeJjsh+TLl5y62EQwh7b6GHMS6SQz449DKrxz8fPQLrhyImZgFkBxBCOWIQM9p+L15YJaAJAkKYOJizUKqoUiRIO3cszTVUKQYg/6sra3RoLx01SQP+MA+mM1mY/j3PjArxl+J30Pu4uSW/QAAAABJRU5ErkJggg==);
                        background-repeat: no-repeat;
                        width: 97px;
                        height: 50px;
                        position: absolute;
                        right: -130px;
                        @media (max-width: 1100px) and (min-width: 992px) {
                            display: none;
                        }
                    }
                }
            }
            &_end {
                .arch__col-item {
                    &:before {
                        content: "";
                        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAAAjCAYAAAA33kzeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ0SURBVHgB1VldUtswEJYCDPAGw88Mb+4Nmhu0N0hPUOcEpTdITtByAnKD9gbhBqQnaPrEE0OAF/7dXVdK1+tdWSbJDPpmPPqxtCt9/rSSbWsSxNXV1Q4k7+Ga7O7uzswK0TGJAcjJOp3Ob7jGcJ3f3NzkZoVIjiAgJYcEFWSstdnLy8vp9fX1KRJnVoAUCSowBXKMS7GcQzoGknKzZCRHECimZKYoivICWHcrA5KWrqbkCEJ49Xg4onx+qWpKkiAEJQnzjLS5mi4uLjKzAJIkiCoGi0JdWYblmG9vby+kJmsSAkx65/b29hyyGVtWWntaHME1hHPT1LSARadPT09fIM24cZStlFLAU6oZhZ2mUi+14fB2JR/eBtT34P6OdD+ybvr8/Dzc398fmUjYh4eHMaQfNKNNjnGbBdg2fXjex48G375gST1mLLUj+eH3ID8ykWpCgmqzoYadwfm2ytUhITTRyOUQrG/7EJU2U5hHo5qCBGnlNm20JaP111QgoWkMTQ/K+RoBB8Ojo6OpEaDuYiHZS4Om2yyZbBEz6BhCnP2iqR29T07b3A6tyzc3N3Gn+yz65QpSWK482dgg+a/6f3yS4k1IZYLdmj1jjDTGMjbFPBwWm37e399/pWrqSB08JFVglrcJPU3NntRfuF+EDoS+LDw4y+eikcPqehsbG+eXl5fHc0OSgiSnTXVa3yYCNVVqfSUVSv2b7EttmI/R3t5evyRIcyoZUAY+3259G64K3PlCE9PIEAZe8UFUUFl+2ti1ebh8xcbj4+O7jr/JB8vW8xzKkuFv2OrEpHrpnuRLGiex1eqtgPt0RxdL6qcYi8RdDAdAnrhVYkfByQjFC9qW7iSx0m+7RKivmBjJ1IRnpE+Y7/DGfBsMqEI9PdM+gQAsqoLbcx/EanZpPz5mTgr3re3Irv4EDsPdw8PDCdatm/rEjQT/SkGXhZOQ5U7dpGr13oU36WyJsYMQYI2LL/4spLUPKUmKP6wvqqZ/cHBwRm1UYpAwOD5QToQ2sdpZhTYpqmcZS30qirJ8HDy4S8udxlJhS6cq+g4f/7ucnNIO3ealHYpJsOAD5Gh6ik1LqsketxXzXhhoh2/3fYkYD1TQ/L+SFieIMxsRLAvNhqRMoaw7ID6IMrQjfOUSNo0TKHdD5CCQoKEwADGI0UnyPG1iIiCplS3PgtVX2pB+qj++zBD4Tw3KH+EQeBzz05HGguzu7q7Mb21tGZ9/C8Cx4JggHPSg+C0UBqQ6v8QgPYHioM3f2FaHq7cACKY/YPI96Z50tHBKnULaB2LOTEsk99EeJjsh+TLl5y62EQwh7b6GHMS6SQz449DKrxz8fPQLrhyImZgFkBxBCOWIQM9p+L15YJaAJAkKYOJizUKqoUiRIO3cszTVUKQYg/6sra3RoLx01SQP+MA+mM1mY/j3PjArxl+J30Pu4uSW/QAAAABJRU5ErkJggg==);
                        background-repeat: no-repeat;
                        width: 97px;
                        height: 50px;
                        position: absolute;
                        left: -100px;
                        @media (max-width: 1100px) and (min-width: 992px) {
                            display: none;
                        }
                    }
                }
            }
            .arch__col-item {
                background: #ffffff;
                box-shadow: 0px 10px 52px rgba(35, 65, 99, 0.1);
                border-radius: 20px;
                padding: 23px;
                display: flex;
                margin-top: 24px;
                justify-content: space-between;
                align-items: center;
                position: relative;
                p {
                    font-size: 12px;
                    line-height: 15px;
                    margin-right: 18px;
                }
            }
        }
        .arch__pim {
            background: #1d1f22;
            border-radius: 20px;
            padding: 36px 27px 20px 27px;
            h4 {
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                text-align: center;
                color: #0167ff;
                margin-bottom: 43px;
            }
            .arch__pim-item {
                position: relative;
                div {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                    color: #fffdee;
                    margin-bottom: 12px;
                    padding-top: 15px;
                }
                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    text-align: center;
                    color: #fffdee;
                    padding-bottom: 18px;
                    border-bottom: 1px solid #464646;
                }
                &:last-child {
                    p {
                        border-bottom: none;
                    }
                }
            }
        }
        @media (max-width: 991px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            .arch__pim {
                padding: 39px 27px;
                margin: 93px 0px;
                width: 100%;
                h4 {
                    margin-bottom: 20px;
                    margin-top: 0;
                    font-size: 24px;
                    line-height: 29px;
                }
                &.hidden_lg {
                    width: 100%;
                    margin-top: 0;
                    margin-bottom: 48px;
                    &:before,
                    &:after {
                        display: none;
                    }
                }
                position: relative;
                &:before {
                    content: "";
                    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAAAjCAYAAAA33kzeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ0SURBVHgB1VldUtswEJYCDPAGw88Mb+4Nmhu0N0hPUOcEpTdITtByAnKD9gbhBqQnaPrEE0OAF/7dXVdK1+tdWSbJDPpmPPqxtCt9/rSSbWsSxNXV1Q4k7+Ga7O7uzswK0TGJAcjJOp3Ob7jGcJ3f3NzkZoVIjiAgJYcEFWSstdnLy8vp9fX1KRJnVoAUCSowBXKMS7GcQzoGknKzZCRHECimZKYoivICWHcrA5KWrqbkCEJ49Xg4onx+qWpKkiAEJQnzjLS5mi4uLjKzAJIkiCoGi0JdWYblmG9vby+kJmsSAkx65/b29hyyGVtWWntaHME1hHPT1LSARadPT09fIM24cZStlFLAU6oZhZ2mUi+14fB2JR/eBtT34P6OdD+ybvr8/Dzc398fmUjYh4eHMaQfNKNNjnGbBdg2fXjex48G375gST1mLLUj+eH3ID8ykWpCgmqzoYadwfm2ytUhITTRyOUQrG/7EJU2U5hHo5qCBGnlNm20JaP111QgoWkMTQ/K+RoBB8Ojo6OpEaDuYiHZS4Om2yyZbBEz6BhCnP2iqR29T07b3A6tyzc3N3Gn+yz65QpSWK482dgg+a/6f3yS4k1IZYLdmj1jjDTGMjbFPBwWm37e399/pWrqSB08JFVglrcJPU3NntRfuF+EDoS+LDw4y+eikcPqehsbG+eXl5fHc0OSgiSnTXVa3yYCNVVqfSUVSv2b7EttmI/R3t5evyRIcyoZUAY+3259G64K3PlCE9PIEAZe8UFUUFl+2ti1ebh8xcbj4+O7jr/JB8vW8xzKkuFv2OrEpHrpnuRLGiex1eqtgPt0RxdL6qcYi8RdDAdAnrhVYkfByQjFC9qW7iSx0m+7RKivmBjJ1IRnpE+Y7/DGfBsMqEI9PdM+gQAsqoLbcx/EanZpPz5mTgr3re3Irv4EDsPdw8PDCdatm/rEjQT/SkGXhZOQ5U7dpGr13oU36WyJsYMQYI2LL/4spLUPKUmKP6wvqqZ/cHBwRm1UYpAwOD5QToQ2sdpZhTYpqmcZS30qirJ8HDy4S8udxlJhS6cq+g4f/7ucnNIO3ealHYpJsOAD5Gh6ik1LqsketxXzXhhoh2/3fYkYD1TQ/L+SFieIMxsRLAvNhqRMoaw7ID6IMrQjfOUSNo0TKHdD5CCQoKEwADGI0UnyPG1iIiCplS3PgtVX2pB+qj++zBD4Tw3KH+EQeBzz05HGguzu7q7Mb21tGZ9/C8Cx4JggHPSg+C0UBqQ6v8QgPYHioM3f2FaHq7cACKY/YPI96Z50tHBKnULaB2LOTEsk99EeJjsh+TLl5y62EQwh7b6GHMS6SQz449DKrxz8fPQLrhyImZgFkBxBCOWIQM9p+L15YJaAJAkKYOJizUKqoUiRIO3cszTVUKQYg/6sra3RoLx01SQP+MA+mM1mY/j3PjArxl+J30Pu4uSW/QAAAABJRU5ErkJggg==);
                    background-repeat: no-repeat;
                    height: 37.5px;
                    width: 69px;
                    position: absolute;
                    left: 50%;
                    top: -70px;
                    transform: translateX(-50%) rotate(90deg);
                }
                &:after {
                    content: "";
                    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAAAjCAYAAAA33kzeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ0SURBVHgB1VldUtswEJYCDPAGw88Mb+4Nmhu0N0hPUOcEpTdITtByAnKD9gbhBqQnaPrEE0OAF/7dXVdK1+tdWSbJDPpmPPqxtCt9/rSSbWsSxNXV1Q4k7+Ga7O7uzswK0TGJAcjJOp3Ob7jGcJ3f3NzkZoVIjiAgJYcEFWSstdnLy8vp9fX1KRJnVoAUCSowBXKMS7GcQzoGknKzZCRHECimZKYoivICWHcrA5KWrqbkCEJ49Xg4onx+qWpKkiAEJQnzjLS5mi4uLjKzAJIkiCoGi0JdWYblmG9vby+kJmsSAkx65/b29hyyGVtWWntaHME1hHPT1LSARadPT09fIM24cZStlFLAU6oZhZ2mUi+14fB2JR/eBtT34P6OdD+ybvr8/Dzc398fmUjYh4eHMaQfNKNNjnGbBdg2fXjex48G375gST1mLLUj+eH3ID8ykWpCgmqzoYadwfm2ytUhITTRyOUQrG/7EJU2U5hHo5qCBGnlNm20JaP111QgoWkMTQ/K+RoBB8Ojo6OpEaDuYiHZS4Om2yyZbBEz6BhCnP2iqR29T07b3A6tyzc3N3Gn+yz65QpSWK482dgg+a/6f3yS4k1IZYLdmj1jjDTGMjbFPBwWm37e399/pWrqSB08JFVglrcJPU3NntRfuF+EDoS+LDw4y+eikcPqehsbG+eXl5fHc0OSgiSnTXVa3yYCNVVqfSUVSv2b7EttmI/R3t5evyRIcyoZUAY+3259G64K3PlCE9PIEAZe8UFUUFl+2ti1ebh8xcbj4+O7jr/JB8vW8xzKkuFv2OrEpHrpnuRLGiex1eqtgPt0RxdL6qcYi8RdDAdAnrhVYkfByQjFC9qW7iSx0m+7RKivmBjJ1IRnpE+Y7/DGfBsMqEI9PdM+gQAsqoLbcx/EanZpPz5mTgr3re3Irv4EDsPdw8PDCdatm/rEjQT/SkGXhZOQ5U7dpGr13oU36WyJsYMQYI2LL/4spLUPKUmKP6wvqqZ/cHBwRm1UYpAwOD5QToQ2sdpZhTYpqmcZS30qirJ8HDy4S8udxlJhS6cq+g4f/7ucnNIO3ealHYpJsOAD5Gh6ik1LqsketxXzXhhoh2/3fYkYD1TQ/L+SFieIMxsRLAvNhqRMoaw7ID6IMrQjfOUSNo0TKHdD5CCQoKEwADGI0UnyPG1iIiCplS3PgtVX2pB+qj++zBD4Tw3KH+EQeBzz05HGguzu7q7Mb21tGZ9/C8Cx4JggHPSg+C0UBqQ6v8QgPYHioM3f2FaHq7cACKY/YPI96Z50tHBKnULaB2LOTEsk99EeJjsh+TLl5y62EQwh7b6GHMS6SQz449DKrxz8fPQLrhyImZgFkBxBCOWIQM9p+L15YJaAJAkKYOJizUKqoUiRIO3cszTVUKQYg/6sra3RoLx01SQP+MA+mM1mY/j3PjArxl+J30Pu4uSW/QAAAABJRU5ErkJggg==);
                    background-repeat: no-repeat;
                    height: 37.5px;
                    width: 69px;
                    position: absolute;
                    left: 50%;
                    bottom: -60px;
                    transform: translateX(-50%) rotate(90deg);
                }
            }
            .arch__col {
                width: 100%;
                .arch__col-item {
                    &:before,
                    &:after {
                        display: none;
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}