.form__figure {
    max-width: 400px;
    width: 100%;
}

.form {
    margin-top: 50px;
    padding-bottom: 126px;
    @media (max-width: 768px) {
        padding-bottom: 56px;
    }
}

.form__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form__fields {
    max-width: 520px;
    width: 100%;
    @media (max-width: 991px) {
        max-width: unset;
    }
}

form input,
form textarea {
    background: #f7f7f7;
    border-radius: 20px;
    padding: 12px 35px 12px 35px;
    width: 100%;
    border: none;
    margin-bottom: 17px;
    resize: none;
    @media (max-width: 991px) {
        border-radius: 14px;
        padding: 12px 23px 12px 23px;
    }
}

form p.form__text {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 28px;
    @media (max-width: 991px) {
        font-size: 16px;
        line-height: 20px;
    }
}

.checkbox-button {
    display: flex;
    align-self: center;
    &.error {
        .checkbox-button__label {
            p {
                color: #000 !important;
            }
        }
    }
}

.checkbox-button__label {
    display: flex;
    align-items: center;
}

.checkbox-button__mark {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    display: block;
    border: 2px solid #000;
    position: relative;
    left: 0;
    border-radius: 6px;
    margin-right: 17px;
}

input[type="checkbox"] {
    display: none;
}

.checkbox-button .checkbox-button__input+.checkbox-button__mark:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 16px;
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    max-width: 8px;
    max-height: 8px;
    // background: #ec1b33;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    margin-top: -1px;
}

.checkbox-button .checkbox-button__input:checked+.checkbox-button__mark {
    border-color: #000;
    &:before {
        opacity: 1;
    }
    &+.checkbox-button__title {
        color: #000;
    }
}

.checkbox-button__title {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    @media (max-width: 991px) {
        font-size: 12px;
        line-height: 15px;
    }
}

.checkbox-buttons-group {
    .checkbox-button {
        padding-top: 10px * 1.5;
        &:last-child {
            .checkbox-button__description {
                border-bottom: 0;
            }
        }
    }
}

.form__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 17px;
    .btn {
        padding: 13px 38px;
    }
    .btn[disabled] {
        opacity: 0.7;
    }
    @media (max-width: 768px) {
        .btn {
            width: 100%;
            text-align: center;
        }
    }
}