$black: #141414;
$dark: #525252;
$primary: #0066FF;
$red: #B83024;
$white: #fff;
$muted: #F4F1ED;
.text {
    &_primary {
        color: $primary;
    }
}