.tech {
    h3 {
        margin-bottom: 15px;
        margin-top: 66px;
    }
    h4 {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #a4b0b6;
        margin-bottom: 57px;
    }
    .tech__head {
        min-width: 220px;
        max-width: 220px;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: #000000;
        @media (max-width: 991px) {
            font-size: 16px;
            line-height: 20px;
            min-width: 170px;
            max-width: 170px;
        }
    }
    .tech__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 41px;
        .tech__cols {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .tech__col {
                width: 270px;
                background: #ffffff;
                box-shadow: 0px 10px 52px rgba(35, 65, 99, 0.1);
                border-radius: 10px;
                text-align: center;
                padding: 8px;
                font-weight: 500;
                font-size: 22px;
                line-height: 29px;
                text-align: center;
                color: #000000;
                position: relative;
                &_black {
                    color: #fff;
                    background: #000;
                }
                &_block {
                    width: 100%;
                }
                &-transparent {
                    opacity: 0;
                }
                .tech__dot {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    left: 20px;
                    top: 50%;
                    position: absolute;
                    transform: translateY(-50%);
                }
                @media (max-width: 991px) {
                    min-width: 270px;
                    max-width: 270px;
                    margin-right: 20px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
}

.tech__content {
    @media (max-width: 991px) {
        overflow-x: scroll;
        // &::-webkit-scrollbar-thumb {
        //     background-color: blue;
        //     // outline: 1px solid slategrey;
        // }
    }
}

.tech__info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 28px;
    margin-top: 50px;
    @media (max-width: 991px) {
        grid-template-columns: 1fr;
        row-gap: 28px;
    }
    .tech__info-card {
        background: #ffffff;
        box-shadow: 0px 10px 52px rgba(35, 65, 99, 0.1);
        border-radius: 20px;
        padding: 23px 28px;
        h6 {
            font-weight: 600;
            font-size: 30px;
            line-height: 37px;
            /* identical to box height */
            color: #000000;
            grid-area: info-title;
            margin-top: 0;
            margin-bottom: 0;
            align-self: center;
            @media (max-width: 991px) {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: #000000;
            }
        }
        li {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #000000;
            position: relative;
            padding-left: 20px;
            &:before {
                content: '';
                width: 2px;
                height: 2px;
                position: absolute;
                background: #000;
                border-radius: 50%;
                left: 0;
                // top: 50%;
                top: 8px;
                // transform: translateY(-50%)
            }
            @media (max-width:991px) {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
            }
        }
        ul {
            grid-area: info-list;
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 8px;
            column-gap: 8px;
            list-style-type: none;
            padding-left: 0;
            @media (max-width: 991px) {
                grid-template-columns: 1fr
            }
        }
        img {
            grid-area: info-image;
        }
        display: grid;
        grid-template-areas: "info-title info-image" "info-list info-list";
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto;
        row-gap: 14px;
    }
}