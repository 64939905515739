.reviews {
    margin-top: 100px;
    .swiper-slide {
        background: #ffffff;
        box-shadow: 0px 10px 52px rgba(35, 65, 99, 0.1);
        border-radius: 20px;
        // width: 600px !important;
    }
    .swiper-container {
        padding: 30px 0px;
    }
}

.slick-slide {
    padding: 0 30px;
}


/* the parent */

// .slick-list {
//     margin: 0 -27px;
// }
.slick-track {
    padding: 0 0 50px 0px;
}

.reviews .slick-dots li button:before {
    width: 13px;
    height: 6px;
    border-radius: 15px;
    background: #c4c4c4;
    content: "";
}

.reviews .slick-dots li.slick-active button:before {
    background: #0066ff;
    // width: 27px;
}

.reviews li.slick-active {
    width: 27px;
    button {
        width: 27px;
        &:before {
            width: 27px;
        }
    }
}

.reviews__block {
    border-radius: 20px;
    padding: 27px 27px 23px 27px;
    background: #ffffff;
    box-shadow: 0px 10px 52px rgba(35, 65, 99, 0.1);
    min-height: 310px;
    .reviews__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .reviews__logo {
            width: 100px;
            margin-right: 15px;
        }
        .reviews__name {
            font-weight: 600;
            font-size: 30px;
            line-height: 37px;
            color: #000000;
            margin-bottom: 7px;
        }
        .reviews__agent {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #afb4bb;
            margin-bottom: 5px;
        }
        .reviews__position {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: #afb4bb;
            margin-bottom: 0px;
        }
    }
    .reviews__text {
        margin-top: 36px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        font-style: italic;
    }
}

@media (max-width: 1500px) {
    .reviews__block {
        .reviews__header {
            .reviews__name {
                font-size: 26px;
                line-height: 32px;
            }
            .reviews__agent {
                font-size: 18px;
            }
            .reviews__position {
                font-size: 16px;
            }
            .reviews__logo {
                width: 80px;
            }
        }
        .reviews__text {
            font-size: 14px;
        }
    }
}

@media (max-width: 991px) {
    .reviews {
        .slick-slide {
            padding: 0 15px;
            padding-bottom: 30px !important;
        }
        // .slick-track {
        //     padding: 0 0 20px 0px;
        // }
        .reviews__block {
            padding: 16px 12px;
            .reviews__header {
                .reviews__logo {
                    width: 70px;
                }
                .reviews__info {
                    .reviews__name {
                        font-size: 20px;
                        line-height: 24px;
                        color: #000000;
                    }
                    .reviews__agent {
                        font-size: 16px;
                        line-height: 20px;
                        color: #afb4bb;
                    }
                    .reviews__position {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
            }
            .reviews__text {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
            }
        }
    }
}