.btn {
    cursor: pointer;
    padding: 8px 18px;
    font-size: 16px;
    line-height: 22px;
    border-radius: 16px;
    transition: .2s;
    &_primary {
        background: $primary;
        color: $white;
        // box-shadow: 0px 4px 8px #FFDFD5;
        border: 1px solid $primary;
        &.btn_light {
            color: $primary;
            background: transparent;
            &:hover {
                background: $primary;
                color: $white;
            }
        }
    }
    &_lg {
        padding: 18px 47px;
    }
}