.footer {
    background: #f7f7f7;
    padding: 57px 0px;
    .footer__inner {
        display: flex;
        justify-content: space-between;
        @media (max-width: 991px) {
            flex-direction: column-reverse;
        }
    }
    .nav__list {
        padding-left: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .nav__item {
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            margin: 0px 15px;
            &:hover {
                opacity: 0.7;
            }
        }
        @media (max-width: 991px) {
            padding-left: 0;
            margin-left: 0;
            // flex-direction: column;
            // align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            .nav__item {
                margin: 0 15px 15px 0px;
            }
        }
    }
    .footer__logo {
        width: 170px;
        @media (max-width: 991px) {
            display: flex;
            margin: 0 auto;
        }
    }
}

.footer__contacts-item {
    display: flex;
    margin-top: 30px;
    .footer__contacts-key {
        width: 105px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height */
        color: #000000;
    }
    .footer__contacts-value,
    .footer__contacts-value a {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        /* identical to box height, or 157% */
        color: #3c3c3c;
    }
}

.footer__copyright {
    margin-top: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    color: #3c3c3c;
}

.footer__info {
    padding-left: 55px;
    @media (max-width: 991px) {
        padding-left: 0;
    }
}

.footer__info-row {
    display: grid;
    margin-bottom: 32px;
    grid-template-columns: 1fr auto;
    column-gap: 56px;
    &:last-child {
        margin-bottom: 0;
    }
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */
    color: #3c3c3c;
    p a,
    p a:visited {
        color: #0066FF;
        cursor: pointer;
    }
    .footer-info__link {
        color: #3C3C3C
    }
    @media (max-width: 991px) {
        grid-template-columns: 1fr;
        margin-bottom: 24px;
        .footer-info__link {
            margin-bottom: 24px;
            &:last-child {
                // margin-bottom: 0;
                margin-bottom: 40px;
            }
        }
        p {
            margin-top: 0;
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }
            br {
                display: none;
            }
        }
    }
}