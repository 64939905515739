.info {
    margin-top: 70px;
    background-image: url("../../../assets/images/man2.png");
    background-repeat: no-repeat;
    // background-size: 35%;
    background-size: 650px;
    background-position-x: 80%;
    background-position-y: 100%;
    @media (max-width: 1700px) {
        background-size: 650px;
    }
    @media (max-width: 1400px) {
        background-position-x: 100%;
        // background-size: 50%;
        background-size: 650px;
    }
    @media (max-width: 1100px) {
        background-size: 600px;
    }
    h1 {
        font-weight: 800;
        font-size: 70px;
        line-height: 85px;
        letter-spacing: 0.15em;
        color: #000000;
    }
    .info__content {
        padding-top: 75px;
        background: url("../../../assets/images/dots.svg") no-repeat;
        background-position-x: 10%;
        background-position-y: 20%;
        p {
            font-weight: 400;
            font-size: 20px;
            line-height: 36px;
            color: #a3b0b6;
            margin-top: 40px;
            margin-bottom: 140px;
            width: 50%;
            @media (max-width: 991px) {
                width: 100%;
            }
        }
        .btn {
            margin-bottom: 100px;
            display: inline-block;
        }
    }
    @media (max-width: 991px) {
        margin-top: 20px;
        background: none;
        h1 {
            margin-top: 0;
            font-size: 32px;
            line-height: 58px;
            letter-spacing: unset;
        }
        .info__content {
            padding-top: 100px;
            p {
                font-size: 16px;
                line-height: 29px;
                margin-top: 28px;
                margin-bottom: 40px;
            }
            .btn {
                width: 100%;
                padding: 10px;
                text-align: center;
                font-size: 16px;
                line-height: 19px;
                position: relative;
            }
            .man {
                width: 75%;
                margin: 0 auto;
                display: flex;
                margin-bottom: -5px;
            }
        }
    }
    @media (max-width: 550px) {
        .info__content {
            .man {
                width: 100%;
            }
            p {
                width: 100%;
            }
        }
    }
}