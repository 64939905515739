.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    background: #fff;
    padding: 15px 0px;
    z-index: 10;
    .header__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .nav__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .nav__item {
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            margin: 0px 15px;
            &:hover {
                opacity: 0.7;
            }
        }
    }
    .header__logo {
        width: 170px;
    }
    @media (max-width: 991px) {
        display: none;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.75);
        backdrop-filter: blur(14px);
        z-index: 9999;
        &.active {
            display: flex;
        }
        .container {
            height: inherit;
        }
        .header__content {
            flex-direction: column;
            justify-content: space-between;
            height: inherit;
            padding-top: 60px;
            align-items: flex-start;
        }
        .header__logo {
            display: none;
        }
        .nav__list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 0;
            padding-left: 0;
            .nav__item {
                color: #fff;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                margin: 0 22px;
                margin-bottom: 28px;
                margin-right: 0;
                text-align: left;
            }
        }
        .btn_block {
            border-radius: 14px;
            width: calc(100% - 40px);
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
}

.mobile-header {
    @media (min-width: 992px) {
        display: none;
    }
    .nothing {
        width: 1px;
        height: 1px;
        opacity: 0;
    }
    @media (max-width: 991px) {
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0 20px;
        z-index: 99999;
        box-shadow: 0 4px 50px rgb(0 0 0 / 15%);
        .toggler {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 24px;
            width: 24px;
            span {
                width: 24px;
                height: 3px;
                background: #000000;
                border-radius: 7px;
                display: block;
                position: absolute;
                -webkit-transition: 0.3s ease-in-out;
                -moz-transition: 0.3s ease-in-out;
                -o-transition: 0.3s ease-in-out;
                transition: 0.3s ease-in-out;
                cursor: pointer;
                &:first-child {
                    top: 0px;
                }
                &:nth-child(2) {
                    top: 7px;
                }
                &:last-child {
                    top: 14px;
                }
                // &:last-child {
                //     margin-bottom: 0;
                // }
            }
        }
        .mobile-header__wrapper {
            padding: 12px 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &.active {
            background: none;
            box-shadow: none;
            .toggler {
                span {
                    background: #fff;
                    &:first-child {
                        top: 7px;
                        -webkit-transform: rotate(135deg);
                        -moz-transform: rotate(135deg);
                        -o-transform: rotate(135deg);
                        transform: rotate(135deg);
                    }
                    &:nth-child(2) {
                        opacity: 0;
                        left: -60px;
                    }
                    &:last-child {
                        top: 7px;
                        -webkit-transform: rotate(-135deg);
                        -moz-transform: rotate(-135deg);
                        -o-transform: rotate(-135deg);
                        transform: rotate(-135deg);
                    }
                }
            }
        }
    }
}

.hidden_xs {
    @media (max-width: 768px) {
        display: none;
    }
}

.hidden_md {
    @media (min-width: 768px) and (max-width: 991px) {
        display: none;
    }
}

.hidden_lg {
    @media (min-width: 992px) {
        display: none;
    }
}

.btn_block {
    width: 100%;
    text-align: center;
}