.table {
    .tr {
        display: flex;
    }
    .thead {
        .th {
            white-space: pre-wrap;
            vertical-align: middle;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #000000;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 138px;
            padding: 17px 0 10px;
            &:first-child {
                border-top-left-radius: 12px;
                width: 50%;
                padding: 10px 24px 13px;
            }
            &:last-child {
                border-top-right-radius: 12px;
            }
            @media (max-width: 991px) {
                font-size: 12px;
                line-height: 15px;
                &:first-child {
                    min-width: 450px;
                    max-width: 450px;
                }
                min-width: 150px;
                width: 150px;
                max-width: 150px;
            }
        }
    }
    .tbody {
        .tr {
            .th:first-child {
                text-align: left;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
                padding: 10px 24px 13px;
                width: 50%;
                justify-content: flex-start;
            }
            .th {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 138px;
                vertical-align: middle;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                text-align: center;
                color: #000000;
                &.primary {
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 18px;
                    text-align: center;
                    color: #0066ff;
                }
                @media (max-width: 991px) {
                    font-size: 12px;
                    line-height: 15px;
                    &:first-child {
                        min-width: 450px;
                        max-width: 450px;
                    }
                    min-width: 150px;
                    width: 150px;
                    max-width: 150px;
                }
            }
            &:last-child {
                .th {
                    &:first-child {
                        border-bottom-left-radius: 12px;
                    }
                    &:last-child {
                        border-bottom-right-radius: 12px;
                    }
                }
            }
        }
    }
    .th {
        border: 1px solid #d8d8d8;
    }
    @media (max-width: 991px) {
        overflow-x: scroll;
    }
}

.compare {
    padding: 40px;
    @media (max-width: 991px) {
        padding: 40px 0px;
    }
}