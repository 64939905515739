.features__content {
    background: #1d1f22;
    border-radius: 50px;
    padding: 66px 140px;
    h3 {
        color: #fff;
    }
}

.features {
    padding-bottom: 60px;
}

section.features h3 {
    margin-bottom: 62px;
}

.features .slick-dots li button:before {
    width: 13px;
    height: 6px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.3);
    content: "";
}

.features .slick-dots li.slick-active button:before {
    background: #fff;
    // width: 27px;
}

.features li.slick-active {
    width: 27px;
    button {
        width: 27px;
        &:before {
            width: 27px;
        }
    }
}

.features {
    .slick-prev,
    .slick-next {
        width: 45px;
        height: 45px;
    }
    .slick-prev:before,
    .slick-next:before {
        width: 45px;
        height: 45px;
        display: block;
        content: "";
        background: url("../../../assets/images/arrowslider.png");
        background-repeat: no-repeat;
    }
    .slick-next:before {
        transform: scaleX(-1);
    }
}

.features__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .features__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #ffffff;
        margin-bottom: 36px;
    }
    .features__subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
    }
    .features__text {
        padding-right: 30px;
    }
    .features__image {
        max-width: 350px;
        width: 100%;
        border-radius: 6px;
    }
    @media (max-width: 991px) {
        .features__title {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;
            margin-bottom: 10px;
        }
        .features__subtitle {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #ebebeb;
        }
        flex-direction: column-reverse;
        .features__image {
            width: 100%;
            max-width: unset;
        }
        .features__text {
            margin-top: 31px;
        }
    }
}

.screenshot {
    height: 550px;
    margin: 0 auto;
    display: flex;
    align-self: center;
    @media (max-width: 991px) {
        height: unset;
        width: 100%;
    }
}

.rodal-mask {
    background: rgba(0, 0, 0, 0.6) !important;
}

.features__content {
    @media (max-width: 991px) {
        padding: 28px;
        border-radius: 24px;
        .slick-track {
            padding-bottom: 0;
            .slick-slide {
                padding: 0;
            }
        }
        .slick-arrow {
            display: none !important;
        }
    }
}

.features {
    h3 {
        &.hidden_lg {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 16px;
            text-align: center;
            color: #000000;
        }
    }
}

.rodal {
    @media (max-width: 991px) {
        z-index: 9999999 !important;
        .rodal-dialog {
            width: unset !important;
            height: 100% !important;
            display: flex;
        }
    }
}